import React from 'react'
import MedicationList from './MedicationList'
import MedicationDetail from './MedicationDetail'

interface MedicationsProps {
    patientData: any;
    medicationData: any;
    medicationDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}

const Medications: React.FC<MedicationsProps> = ({ patientData, medicationData, medicationDetailId, page, isFetching, handleChangePage,totalPages, currentPage, pageLinks  }) => {
    return (
        <>
            {
                medicationDetailId && page === 'MedicationRequest' ? (
                    <MedicationDetail
                        medicationDetailId={medicationDetailId}
                        medicationData={medicationData}
                        isFetching={isFetching}
                        patientData={patientData} />
                ) : <MedicationList
                    medicationData={medicationData}
                    isFetching={isFetching}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageLinks={pageLinks} />

            }
        </>
    )
}

export default Medications