import React from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Boolean from '../../../DataTypes/Boolean'
import { Organization } from '../../../../../types/FHIRTypes/Organization'
import useEnvironment from '../../../../../hooks/location/useEnviroment'

interface NetworkListProps {
    networks: any
    page: number
    isFetching: boolean
    handleNextPage: () => void
    handlePrevPage: () => void
    isLoading: boolean
    isSuccess: boolean
    clickViewDataLink: (organization: Organization) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
    handleChangePage: (page: number | string) => void
}

const NetworkList: React.FC<NetworkListProps> = ({ networks, clickViewDataLink, page, isFetching, handleNextPage, handlePrevPage, isLoading, isSuccess, totalPages, currentPage, pageLinks, handleChangePage }) => {
    const { data: environment } = useEnvironment()
    return (

        <section>
            <h3>Networks</h3>
            <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                    networks && networks?.data.length > 0 && (
                        <tr>
                            <td scope="row" colSpan={4}>
                                <PaginationButtons
                                    onBackClick={handlePrevPage}
                                    onNextClick={handleNextPage}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    pageLinks={pageLinks}
                                    handleChangePage={handleChangePage}
                                />
                            </td>
                        </tr>
                    )
                }
                {networks && networks.total > 0 && environment?.includeTotalInFHIRRequest && (
                    <span className='d-md-block'>
                        {(isLoading || isFetching)
                            ? <Spinner animation="border" size="sm" />
                            : `${networks.total.toLocaleString()} record(s) returned`
                        }
                    </span>
                )}


            </div>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                        <th className='type-column'>Type</th>
                        <th className='active-column'>Active</th>
                        <th className='active-column'>View Detail</th>

                    </tr>
                </thead>
                <tbody>
                    {isLoading || isFetching ? (
                        <tr>
                            <td colSpan={4} className="text-center">Loading...</td>
                        </tr>
                    ) : !networks?.data?.length ? (
                        <tr>
                            <td colSpan={4} className="text-center">No Networks Found.</td>
                        </tr>
                    ) : networks && isSuccess ? (
                        networks.data.map((network: any) => (
                            <tr key={network.id}>
                                <td>
                                    <Link to='#' onClick={() => clickViewDataLink(network)}>{network.name}</Link>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                    <CodeableConcept
                                        data={networks?.type}
                                        dataExtension={networks?._type}
                                    />
                                </td>
                                {/* Safely render network.active */}
                                <td className="d-none d-lg-table-cell">
                                    <Boolean data={networks?.active} dataExtension={networks?._active} />
                                </td>
                                <td className='d-none d-lg-table-cell'>
                                    <Link to='#' onClick={() => clickViewDataLink(network)}>View Detail</Link>
                                </td>
                            </tr>
                        ))) : null}
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                        {
                            networks && networks?.data.length > 0 && (
                                <tr>
                                    <td scope="row" colSpan={4}>
                                        <PaginationButtons
                                            onBackClick={handlePrevPage}
                                            onNextClick={handleNextPage}
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            pageLinks={pageLinks}
                                            handleChangePage={handleChangePage}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </div>
                </tfoot>
            </Table>
        </section>
    )
}

export default NetworkList
