import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Extension as FHIRExtension } from '../../../../types/FHIRTypes/Extension'
import DataAbsentReason from '../DataAbsentReason'

interface DateProps {
  data?: string
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}


const Date: FC<DateProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

 const getDateDisplay = (): string => data ?? ''
  return (
    <div>{getDateDisplay()}</div>
  )
}

export default Date