import React from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import Extension from '../Extension'
import { ref } from 'yup'

interface ReferenceProps {
    data?: FHIRReference | FHIRReference[]
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Reference: React.FC<ReferenceProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) return null

    if (dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const dataArray = Array.isArray(data) ? data : data ? [data] : []
    const displayValue = (ref: FHIRReference) => ref.display || ref.reference || ref.identifier?.value || ''

    const renderContent = (item: FHIRReference) => {
        const value = displayValue(item)
        if (value) {
            return <span>{value}</span>
        }
        return <Extension nestedExtension={item.extension} />
    }

    return (
        <span>
          {dataArray.map((item, index) => (
            <span key={index}>
              {renderContent(item)}
            </span>
          ))}
        </span>
      )
}

export default Reference