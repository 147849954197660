import React, { FC } from 'react'
import DataAbsentReason from '../DataAbsentReason'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'

interface CodeProps {
  data: string | string[] | undefined
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Code: FC<CodeProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }
  
  if (dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }
  
  return (
    <div>
      {Array.isArray(data) ? (
        data.map((val, index) => (
          <React.Fragment key={index}>
            {val}
            <br />
          </React.Fragment>
        ))
      ) : (
        <span>{data}</span>
      )}
    </div>
  )
}

export default Code