import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface StringProps {
  data?: string | string[] | null
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const StringDisplay: FC<StringProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  return (
    <div>
      {Array.isArray(data) ? (
        data.map((val, index) => (
          <React.Fragment key={index}>
            {val}
            <br />
          </React.Fragment>
        ))
      ) : (
        <span>{data}</span>
      )}
    </div>
  )
}

export default StringDisplay