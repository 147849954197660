import React, { FC, useEffect, useState } from 'react'
import isAuthenticated from '../../routes/PrivateRoute'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useGlobalState } from '../../hooks/store/useGlobalState'
import useProviderFhirResource from '../../hooks/admin/useProviderFhirResource'
import { useHistory, useParams } from 'react-router-dom'
import { OrganizationDetail, OrganizationList } from '../../components/FhirData/FhirResources/Organization'
import { PractitionerDetail, PractitionerList } from '../../components/FhirData/FhirResources/Practitioner'
import { NetworkList } from '../../components/FhirData/FhirResources/Network'
import PermissionGate from '../../components/PermissionGate'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import NetworkDetail from '../../components/FhirData/FhirResources/Network/NetworkDetail'
import { Organization } from '../../types/FHIRTypes/Organization'
import { Practitioner } from '../../types/FHIRTypes/Practitioner'
import { scrollToTop } from '../../utils/helpers'
import usePaginatedProviderFhirResource from '../../hooks/admin/usePaginatedProviderResource'

interface RouteParams {
    resourceId: string | undefined
}

interface ResourceState<T> {
    data: T;
    isLoading: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    remove: () => void;
    isError: boolean;
    fetchResources: () => void;
}

interface ResourceMap {
    Organization: ResourceState<any>;
    Practitioner: ResourceState<any>;
    Network: ResourceState<any>;
}

/**
 *  NOTE: For now, only admins can view the provider directory. This will be updated in the future once it has gone through a redesign and is ready for public use. 
 *  As discussed by @tyler-gotz and @emilynorton and documented in JIRA ticket: https://teschglobal.atlassian.net/browse/PORTAL-723
 */
const ProviderList: FC = () => {
    const {
        providerResource,
        setProviderResource,
        providerSearchTerms,
        setProviderSearchTerms,
        setProvider,
        setOrganization,
        provider,
        organization
    } = useGlobalState((state) => ({
        providerResource: state.providerResource,
        providerSearchTerms: state.providerSearchTerms,
        setProviderSearchTerms: state.setProviderSearchTerms,
        setProvider: state.setProvider,
        setOrganization: state.setOrganization,
        provider: state.provider,
        organization: state.organization,
        setProviderResource: state.setProviderResource
    }))

    const [viewPressed, setViewPressed] = useState(true)
    const [page, setPage] = useState(0)
    const [pagesOffset, setPagesOffset] = useState('')
    const [fhirPageState, setFhirPageState] = useState('')

    const [key, setKey] = useState(providerResource)

    const { resourceId } = useParams<RouteParams>()
    const history = useHistory()

    const handleChangeKey = (k: string | null) => {
        setKey(k || '')
        setProviderResource(k || '')
        handleClearForm()
        newSearch()
        if (resourceId) {
            history.push('/provider-list')
        }
    }

    const { user } = useUserWithRole()

    const {
        refetch: fetchOrganizations,
        data: organizations,
        isLoading: isLoadingOrganizations,
        isSuccess: isSuccessOrganizations,
        isFetching: isFetchingOrganizations,
        remove: removeOrganizations,
        isError: isErrorOrganizations,
        totalPages: organizationTotalPages,
        currentPage: organizationCurrentPage,
        pageLinks: organizationPageLinks,
        handleChangePage: handleChangeOrganization
        } = usePaginatedProviderFhirResource({
        resource: 'Organization',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchPractitioners,
        data: practitioners,
        isLoading: isLoadingPractitioners,
        isSuccess: isSuccessPractitioners,
        isFetching: isFetchingPractitioners,
        remove: removePractitioners,
        isError: isErrorPractitioners,
        totalPages: practitionerTotalPages,
        currentPage: practitionerCurrentPage,
        pageLinks: practitionerPageLinks,
        handleChangePage: handleChangePractitioner
    } = usePaginatedProviderFhirResource({
        resource: 'Practitioner',
        searchTerms: providerSearchTerms,
    }, 25)

    const {
        refetch: fetchNetworks,
        data: networks,
        isLoading: isLoadingNetworks,
        isSuccess: isSuccessNetworks,
        isFetching: isFetchingNetworks,
        remove: removeNetworks,
        isError: isErrorNetworks,
        totalPages: networkTotalPages,
        currentPage: networkCurrentPage,
        pageLinks: networkPageLinks,
        handleChangePage: handleChangeNetwork
    } = usePaginatedProviderFhirResource({
        resource: 'Network',
        searchTerms: providerSearchTerms,
    }, 25)

    const resourceMap: ResourceMap = {
        Organization: {
            data: organizations,
            isLoading: isLoadingOrganizations,
            isSuccess: isSuccessOrganizations,
            isFetching: isFetchingOrganizations,
            remove: removeOrganizations,
            isError: isErrorOrganizations,
            fetchResources: fetchOrganizations,
        },
        Practitioner: {
            data: practitioners,
            isLoading: isLoadingPractitioners,
            isSuccess: isSuccessPractitioners,
            isFetching: isFetchingPractitioners,
            remove: removePractitioners,
            isError: isErrorPractitioners,
            fetchResources: fetchPractitioners,
        },
        Network: {
            data: networks,
            isLoading: isLoadingNetworks,
            isSuccess: isSuccessNetworks,
            isFetching: isFetchingNetworks,
            remove: removeNetworks,
            isError: isErrorNetworks,
            fetchResources: fetchNetworks,
        },
    }


    const handleNextPage = () => {
        const resourceKey = key as keyof ResourceMap
        const resource = resourceMap[resourceKey]
        const nextLinkQueryParams = resource.data.nextLink.substring(resource.data.nextLink.indexOf('?'))
        const queryParams = new URLSearchParams(nextLinkQueryParams)
        setPage((prevPage) => prevPage += 1)
        setPagesOffset(queryParams.get('_getpagesoffset') || '')
        setFhirPageState(queryParams.get('_getpages') || '')
    }

    const handlePrevPage = () => {
        const resourceKey = key as keyof ResourceMap
        const resource = resourceMap[resourceKey]
        const previousLinkQueryParams = resource.data.previousLink.substring(resource.data.previousLink.indexOf('?'))
        const queryParams = new URLSearchParams(previousLinkQueryParams)
        setPage((prevPage) => prevPage -= 1)
        setPagesOffset(queryParams.get('_getpagesoffset') || '')
        setFhirPageState(queryParams.get('_getpages') || '')
    }

    useEffect(() => {
        const resourceKey = key as keyof ResourceMap
        const resource = resourceMap[resourceKey]
        if (pagesOffset && fhirPageState) {
            resource.fetchResources()
        }

        if (page === 0 && pagesOffset === '' && fhirPageState === '' && viewPressed) {
            resource.fetchResources()
            setViewPressed(false)
        }

    }, [pagesOffset, fhirPageState, page, viewPressed])

    const newSearch = () => {
        const resourceKey = key as keyof ResourceMap
        const resource = resourceMap[resourceKey]
        resource.remove()
        setViewPressed(true)
        setPage(0)
        setPagesOffset('')
        setFhirPageState('')
    }

    const handleLinkClick = (resource: Organization | Practitioner) => {

        if (key === 'Organization' || key === 'Network') {
            setOrganization(resource as Organization)
        }

        if (key === 'Practitioner') {
            setProvider(resource as Practitioner)
        }
        scrollToTop()
        history.push(`/provider-list/${resource.id}`)
    }

    const handleClearForm = () => {
        setProviderSearchTerms('')
        newSearch()
    }

    const handleUpdateSearchTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProviderSearchTerms(e.target.value)
    }
 
   return (
        <PermissionGate
            user={user}
            role='admin'
        >
            <Container
                style={{
                    padding: '10px 0',
                }}
            >
                <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
                    <h2>Provider Directory</h2>
                    <Row>
                        <Col sm={2}>
                            <Nav variant='pills' className='flex-column'>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link style={{ color: 'white' }} eventKey='Organization'>Organizations</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='Practitioner'>Practitioners</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='patient-pill'>
                                    <Nav.Link eventKey='Network'>Networks</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10} className='mt-sm-0 mt-3'>
                            <Tab.Content>
                                <Tab.Pane eventKey='Organization'>
                                    {
                                        key === 'Organization' && (
                                            <Tab.Content>
                                                {
                                                    resourceId ? (
                                                        <OrganizationDetail organization={organization} />
                                                    ) : (
                                                        <OrganizationList
                                                            organizations={organizations}
                                                            clickViewDataLink={handleLinkClick}
                                                            searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                            page={page}
                                                            isFetching={isFetchingOrganizations}
                                                            handleNextPage={handleNextPage}
                                                            handlePrevPage={handlePrevPage}
                                                            isLoading={isLoadingOrganizations}
                                                            isSuccess={isSuccessOrganizations}
                                                            clearForm={handleClearForm}
                                                            newSearch={newSearch}
                                                            updateSearchTerms={handleUpdateSearchTerms}
                                                            totalPages={organizationTotalPages}
                                                            currentPage={organizationCurrentPage}
                                                            pageLinks={organizationPageLinks}
                                                            handleChangePage={handleChangeOrganization}
                                                        />
                                                    )
                                                }
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey='Practitioner'>
                                    {
                                        key === 'Practitioner' && (
                                            <Tab.Content>
                                                {
                                                    resourceId ? (
                                                        <PractitionerDetail resource={provider} />
                                                    ) : (
                                                        <PractitionerList
                                                            practitioners={practitioners}
                                                            clickViewDataLink={handleLinkClick}
                                                            searchProviderResource={{ searchTerms: providerSearchTerms, resource: '' }}
                                                            page={page}
                                                            isFetching={isFetchingPractitioners}
                                                            handleNextPage={handleNextPage}
                                                            handlePrevPage={handlePrevPage}
                                                            isLoading={isLoadingPractitioners}
                                                            isSuccess={isSuccessPractitioners}
                                                            clearForm={handleClearForm}
                                                            newSearch={newSearch}
                                                            updateSearchTerms={handleUpdateSearchTerms}
                                                            totalPages={practitionerTotalPages}
                                                            currentPage={practitionerCurrentPage}
                                                            pageLinks={practitionerPageLinks}
                                                            handleChangePage={handleChangePractitioner}
                                                        />
                                                    )
                                                }
                                            </Tab.Content>
                                        )
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="Network">
                                    {
                                        key === 'Network' && (
                                            <Tab.Content>
                                                {resourceId ? (
                                                    <NetworkDetail networks={organization} />
                                                ) : (
                                                    <NetworkList
                                                        networks={networks}
                                                        clickViewDataLink={handleLinkClick}
                                                        page={page}
                                                        isFetching={isFetchingNetworks}
                                                        handleNextPage={handleNextPage}
                                                        handlePrevPage={handlePrevPage}
                                                        isLoading={isLoadingNetworks}
                                                        isSuccess={isSuccessNetworks}
                                                        totalPages={networkTotalPages}
                                                        currentPage={networkCurrentPage}
                                                        pageLinks={networkPageLinks}
                                                        handleChangePage={handleChangeNetwork}
                                                    />
                                                )}
                                            </Tab.Content>
                                        )
                                    }

                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </PermissionGate>
    )
}

export default isAuthenticated(ProviderList)