import React from 'react'
import { SupportingInfo } from '../../../../types/FHIRTypes/ExplanationOfBenefit'
import { handleCodeableConcept } from '../../../../utils/helpers'
import Date from '../../DataTypes/Date'
import { getDataAbsentReason } from '../../../../utils/fhirUtils/getDataAbsentReason'
import Quantity from '../../DataTypes/Quantity'
import OrganizationReference from '../OrganizationReference'
import Coding from '../../DataTypes/Coding'
import StringDisplay from '../../DataTypes/String'
import { CodeableConcept as FHIRCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Quantity as FHIRQuantity } from '../../../../types/FHIRTypes/Quantity'
import { Period as FHIRPeriod } from '../../../../types/FHIRTypes/Period'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Extension from '../../DataTypes/Extension'
import Period from '../../DataTypes/Period'

interface EobSupportingInfoProps {
    supportingInfo?: SupportingInfo
}

const renderStringValue = (code?: FHIRCodeableConcept, valueString?: string, _valueString?: FHIRCodeableConcept | FHIRCodeableConcept[]) => {
    return (valueString || _valueString) ? <StringDisplay data={valueString} dataExtension={_valueString} /> : <Extension nestedExtension={code} />
}

const renderQuantity = (code?: FHIRCodeableConcept, valueQuantity?: FHIRQuantity, _valueQuantity?: FHIRCodeableConcept | FHIRCodeableConcept[]) => {
    return (valueQuantity || _valueQuantity) ? <Quantity data={valueQuantity} dataExtension={_valueQuantity} /> : <Extension nestedExtension={code} />
}

const renderDate = (code?: FHIRCodeableConcept, _code?: FHIRCodeableConcept | FHIRCodeableConcept[], timingDate?: string, _timingDate?: FHIRCodeableConcept | FHIRCodeableConcept[], timingPeriod?: FHIRPeriod, _timingPeriod?: FHIRCodeableConcept | FHIRCodeableConcept[]) => {
    if (timingDate || _timingDate) {
        return <Date data={timingDate} dataExtension={_timingDate} />
    }
    if (timingPeriod || _timingPeriod) {
        return <Period data={timingPeriod} dataExtension={_timingPeriod} />
    }
    return <CodeableConcept data={code} dataExtension={_code} />
}

const renderValueAndTime = (code?: FHIRCodeableConcept, _code?: FHIRCodeableConcept | FHIRCodeableConcept[], valueString?: string, _valueString?: FHIRCodeableConcept | FHIRCodeableConcept[], timingDate?: string, _timingDate?: FHIRCodeableConcept | FHIRCodeableConcept[], valueQuantity?: FHIRQuantity, _valueQuantity?: FHIRCodeableConcept | FHIRCodeableConcept[]) => {
    return (
        <div className='d-flex'>
            <span className='mr-2'>
                {(timingDate || _timingDate) && <Date data={timingDate} dataExtension={_timingDate} />}
            </span>
            <span className='mr-2'>
                <StringDisplay data={valueString} dataExtension={_valueString} />
            </span>
            <span className='mr-2'>
                <CodeableConcept data={code} dataExtension={_code} />
            </span>
            <span className='mr-2'>
                <Quantity data={valueQuantity} dataExtension={_valueQuantity} />
            </span>
            + Months
        </div>
    )
}

const renderSupportingInfoValue = (category: string, supportingInfo: SupportingInfo) => {
    switch (category) {
        case 'additionalbodysite':
        case 'admtype':
        case 'brandgenericindicator':
        case 'compoundcode':
        case 'drg':
        case 'discharge-status':
        case 'dawcode':
        case 'missingtoothnumber':
        case 'pointoforigin':
        case 'rxorigincode':
        case 'typeofbill':
            return <CodeableConcept data={supportingInfo.code} dataExtension={supportingInfo._code} />
        case 'admissionperiod':
        case 'clmrecvddate':
            return renderDate(supportingInfo.code, supportingInfo._code, supportingInfo.timingDate, supportingInfo._timingDate, supportingInfo.timingPeriod, supportingInfo._timingPeriod)
        case 'ambulancetransportreason':
        case 'roundtrippurpose':
        case 'stretcherpurpose':
            return (supportingInfo.reason || supportingInfo._reason) ? <Coding data={supportingInfo.reason} dataExtension={supportingInfo._reason}/> : <CodeableConcept data={supportingInfo.code} dataExtension={supportingInfo._code} />
        case 'dropofflocation':
        case 'medicalrecordnumber':
        case 'patientaccountnumber':
        case 'pickuplocation':
            return renderStringValue(supportingInfo.code, supportingInfo.valueString, supportingInfo._valueString)
        case 'dayssupply':
        case 'patientweight':
        case 'refillnum':
        case 'refillsAuthorized':
        case 'transportationdistance':
            return renderQuantity(supportingInfo.code, supportingInfo.valueQuantity, supportingInfo._valueQuantity)
        case 'orthodontics':
        case 'prosthesis':
            return renderValueAndTime(supportingInfo.code, supportingInfo._code, supportingInfo.valueString, supportingInfo._valueString, supportingInfo.timingDate, supportingInfo._timingDate, supportingInfo.valueQuantity, supportingInfo._valueQuantity)
        case 'servicefacility':
            return supportingInfo.valueReference ? <OrganizationReference organization={supportingInfo.valueReference} /> : <>{getDataAbsentReason([])}</>
        default:
            return <>{getDataAbsentReason([])}</>
    }
}


const EobSupportingInfo: React.FC<EobSupportingInfoProps> = ({ supportingInfo }) => {
    if (!supportingInfo) return null

    const category = supportingInfo.category?.coding?.[0].code || ''
    const value = renderSupportingInfoValue(category, supportingInfo)

    return (
        <div className='d-flex'>
            <span className='mr-2'>
                <CodeableConcept data={supportingInfo.category} dataExtension={supportingInfo._category} />
            </span>
            {value}
        </div>
    )
}

export default EobSupportingInfo