import React, { FC } from 'react'
import AllergyList from './AllergyList'
import AllergyDetail from './AllergyDetail'

interface Props {
    allergyData: any;
    patientData: any;
    allergyDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}


const Allergies: FC<Props> = ({ allergyData, patientData, allergyDetailId, page, isFetching, handleChangePage, totalPages, currentPage, pageLinks }: Props) => {
    return (
        <div>
            {
                allergyDetailId && page === 'AllergyIntolerance' ? (
                    <AllergyDetail
                        allergyData={allergyData}
                        isFetching={isFetching}
                        allergyDetailId={allergyDetailId} />
                ) : <AllergyList
                    allergyData={allergyData}
                    patientData={patientData}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    isFetching={isFetching}

                />
            }
        </div>
    )
}

export default Allergies