import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface InstantProps {
  data?: string | string[] | null
  dataExtension?: CodeableConcept | CodeableConcept[] | null
  convertToLocaleDateString?: boolean
}

const Instant: FC<InstantProps> = ({ data, dataExtension, convertToLocaleDateString }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  const displayData = convertToLocaleDateString && data
    ? Array.isArray(data)
      ? data.map(date => new Date(date).toLocaleDateString())
      : new Date(data).toLocaleDateString()
    : data

  return (
    <div>
      {Array.isArray(displayData) ? (
        displayData.join(', ')
      ) : (
        <span>{displayData}</span>
      )}
    </div>
  )
}

export default Instant