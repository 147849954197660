import React, { FC } from 'react'
import { Period as FHIRPeriod } from '../../../../types/FHIRTypes/Period'
import Period from '../../DataTypes/Period'

interface PeriodOrDateTimeProps {
    period?: FHIRPeriod;
    dateTime?: string;
}

const PeriodOrDateTime: FC<PeriodOrDateTimeProps> = ({ period, dateTime }) => {
    if (dateTime) {
        return <span>{dateTime}</span>
    }

    if (period) {
        return <Period data={period} />
    }

    return null
}

export default PeriodOrDateTime