import {create} from 'zustand'
import {persist, createJSONStorage} from 'zustand/middleware'
import {GlobalState} from '../../types/GlobalState'
import {Organization} from '../../types/FHIRTypes/Organization'
import {Practitioner} from '../../types/FHIRTypes/Practitioner'

export const useGlobalState = create<GlobalState>()(
    persist(
        (set) => ({
            fhirId: '',
            setFhirId: (fhirId: string) => set(() => ({fhirId})),
            providerResource: 'Organization',
            setProviderResource: (providerResource: string) => set(() => ({providerResource})),
            providerSearchTerms: '',
            setProviderSearchTerms: (providerSearchTerms: string) => set(() => ({providerSearchTerms})),
            provider: null,
            setProvider: (provider: Practitioner | null) => set(() => ({provider})),
            sessionTimeOut: false,
            setSessionTimeOut: (sessionTimeOut: boolean) => set(() => ({sessionTimeOut})),
            organization: null,
            setOrganization: (organization: Organization | null) => set(() => ({organization})),
        }),
        {
            name: 'global-state-storage',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({
                fhirId: state.fhirId,
                providerResource: state.providerResource,
                providerSearchTerms: state.providerSearchTerms,
                provider: state.provider,
                organization: state.organization
            })
        }
    )
)