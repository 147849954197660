import React, { FC } from 'react'
import { ContactPoint as FhirContactPoint } from '../../../../types/FHIRTypes/ContactPoint'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface ContactPointProps {
    contactPoint: FhirContactPoint | undefined
}

const ContactPoint: FC<ContactPointProps> = ({ contactPoint }) => {
    if (!contactPoint) return null
    
    const getTelecom = () => {
        const useType = contactPoint.use ? `${capitalizeFirstLetter(contactPoint.use)}: ` : ''
        const value = contactPoint.value ? contactPoint.value : ''
        return `${capitalizeFirstLetter(contactPoint.system)}: ${useType} ${value}`
    }

    return (
        <div>
            {getContentOrFallback(getTelecom())}
        </div>
    )
}

export default ContactPoint