import React from 'react'
import { DiagnosticReport } from '../../../../../types/FHIRTypes/DiagnosticReport'
import { DiagnosticReportBundle } from '../../../../../types/FHIRTypes/Bundle'
import PaginationButtons from '../../../../PaginationButtons'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Code from '../../../DataTypes/Code'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import PeriodOrDateTime from '../../../DataTypes/PeriodOrDateTime'

interface DiagnosticReportListProps {
    data?: DiagnosticReportBundle;
    diagnosticReports: DiagnosticReport[];
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}

const DiagnosticReportList: React.FC<DiagnosticReportListProps> = ({ data, diagnosticReports, isFetching, handleChangePage, totalPages, currentPage, pageLinks }) => {

    const nextLink = data && data.link.find((l: any) => l.relation === 'next')
    const previousLink = data && data.link.find((l: any) => l.relation === 'previous')


    return (
        <div>
            {
                data && data.total > 0 && (
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {data.total > 0 &&
                            <div className='mb-2'>
                                <PaginationButtons
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    pageLinks={pageLinks}
                                    handleChangePage={handleChangePage}
                                />
                            </div>
                        }
                        {`${data.total.toLocaleString()} record(s) found`}
                    </div>
                )
            }
            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Code</th>
                        <th className='d-none d-lg-table-cell'>Category</th>
                        <th className='d-none d-lg-table-cell'>Status</th>
                        <th className='d-none d-lg-table-cell'>Effective</th>
                        <th className='d-none d-lg-table-cell'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={5} className='text-center'>
                                    <Spinner animation='border' role='status'>
                                        <span className='sr-only'>Loading Diagnostic Reports...</span>
                                    </Spinner>
                                </td>
                            </tr>
                        ) : data && diagnosticReports.length === 0 ? (
                            <tr>
                                <td colSpan={5} rowSpan={15} className='text-center'>
                                    No Diagnostic Reports found
                                </td>
                            </tr>
                        ) : (
                            <>
                                {
                                    diagnosticReports.map((diagnosticReport) => (
                                        <tr key={diagnosticReport.id}>
                                            <td className='d-table-cell'>
                                                <Link to={`/patient/DiagnosticReport/${diagnosticReport.id}`}>
                                                    <CodeableConcept
                                                        data={diagnosticReport.code}
                                                        dataExtension={diagnosticReport._code}
                                                    />
                                                </Link>
                                            </td>
                                            <td className='d-none d-lg-table-cell'>
                                                <CodeableConcept
                                                    data={diagnosticReport.category}
                                                    dataExtension={diagnosticReport._category}
                                                />
                                            </td>
                                            <td className='d-none d-lg-table-cell'>
                                                <Code
                                                    data={diagnosticReport.status}
                                                    dataExtension={diagnosticReport._status}
                                                />
                                            </td>
                                            <td className='d-none d-lg-table-cell'>
                                                <PeriodOrDateTime
                                                    period={diagnosticReport.effectivePeriod}
                                                    dateTime={diagnosticReport.effectiveDateTime}
                                                />
                                            </td>
                                            <td className='d-none d-lg-table-cell'>
                                                <Link to={`/patient/DiagnosticReport/${diagnosticReport.id}`}>
                                                    Detail
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </>
                        )
                    }
                </tbody>
               
                <tfoot>
                <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                    {
                        data && data.total > 0 && (
                            <tr>
                                <td scope='row' colSpan={5}>
                                    <PaginationButtons
                                        onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                        onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        pageLinks={pageLinks}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </div>
                </tfoot>
            </Table>
        </div>
    )
}

export default DiagnosticReportList