import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Container, Form, Spinner, Table, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useMembers from '../../../hooks/admin/useMembers'
import isAuthenticated from '../../../routes/PrivateRoute'

import '../../../App.scss'
import { useGlobalState } from '../../../hooks/store/useGlobalState'
import { appConfig } from '../../../assets/customizations/config'
import PaginationButtons from '../../../components/PaginationButtons'
import PermissionGate from '../../../components/PermissionGate'
import useUserWithRole from '../../../hooks/authentication/useUserWithRole'
import { getUrlParameter } from '../../../utils/helpers'

const initialFormState = {
  category: 'LastName',
  keyword: ''
}

type MembersResponse = {
  members: any
  total: number,
  nextLink?: string,
  previousLink?: string
}

const Members: FC = () => {
  const { user } = useUserWithRole()
  const [formState, setFormState] = useState(initialFormState)
  const [members, setMembers] = useState({
    members: [],
    total: 0,
    nextLink: '',
    previousLink: ''
  } as MembersResponse)

  const [page, setPage] = useState(0)
  const [pageState, setPageState] = useState({
    pagesOffset: '',
    fhirPageState: '',
    pageId: ''
  })

  const setFhirId = useGlobalState((state) => state.setFhirId)

  const { isLoading, data, refetch, isSuccess, isFetching, isError } = useMembers({
    page,
    category: formState.category,
    searchTerm: formState.keyword,
    pagesOffset: pageState.pagesOffset,
    pageState: pageState.fhirPageState,
    pageId: pageState.pageId
  })

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setMembers(data)
    }
  }, [isFetching, isSuccess])

  const handleMemberSearch = (e: any) => {
    e.preventDefault()
    refetch()
  }

  useEffect(() => {
    if (pageState.pagesOffset !== '' || pageState.pageId !== '') {
      refetch()
    }
  }, [pageState.pagesOffset, pageState.pageId])
  const selfLink = data?.smileMembers?.link?.find((l: any) => l.relation === 'self')?.url
  const pageSize = selfLink && Number(new URL(selfLink).searchParams.get('_count'))
  const totalPages = data?.smileMembers?.total
    ? Math.ceil(data.smileMembers.total / pageSize)
    : 1

  const currentPage = pageState.pagesOffset
    ? Math.floor(Number(pageState.pagesOffset) / pageSize) + 1
    : 1

  const pageLinks = data?.smileMembers.link
    ? data?.smileMembers.link
      .filter((l: any) => l.relation.match(/next|previous|self|first|last/))
      .map((l: any) => {
        const offset = Number(new URL(l.url).searchParams.get('_getpagesoffset')) || 0
        return {
          page: Math.floor(offset / data.members.length) + 1,
          url: l.url,
        }
      })
      .sort((a: any, b: any) => a.page - b.page)
    : []

  const handleChangePage = (pageOrUrl: string | number | undefined) => {
    if (!pageOrUrl) return
    let newPages = ''
    let newOffset = ''
    let newPageId = ''

    if (typeof pageOrUrl === 'string') {
      const queryParams = new URLSearchParams(new URL(pageOrUrl).search)
      newPages = queryParams.get('_getpages') || ''
      newOffset = queryParams.get('_getpagesoffset') || '0'
      newPageId = queryParams.get('_pageId') || ''
    }
    else {
      const link = data?.smileMembers?.link?.find((l: any) =>
        pageOrUrl > currentPage ? l.relation === 'next' : l.relation === 'previous'
      )?.url

      if (link) {
        const urlObj = new URL(link)
        newPages = urlObj.searchParams.get('_getpages') || ''
        newOffset = ((pageOrUrl - 1) * pageSize).toString()
      }
      else {
        newOffset = ((pageOrUrl - 1) * pageSize).toString()
      }
    }
    setPageState(
      {
        fhirPageState: newPages,
        pagesOffset: newOffset,
        pageId: newPageId
      }
    )
  }

  const nextLink = data?.smileMembers && data?.smileMembers.link.find((l: any) => l.relation === 'next')
  const previousLink = data?.smileMembers && data?.smileMembers.link.find((l: any) => l.relation === 'previous')

  const handleClearSearch = () => {
    setFormState(initialFormState)
    setPageState({
      pagesOffset: '',
      fhirPageState: '',
      pageId: ''
    })
    setMembers({
      members: [],
      total: 0,
      nextLink: '',
      previousLink: ''
    } as MembersResponse)
  }

  return (
    <main>
      <PermissionGate
        user={user}
        role='admin'
      >
        <Container>
          {/* <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
          Admin Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active aria-current="page">Members</Breadcrumb.Item>
      </Breadcrumb> */}
          <h2 className="headerText">Members</h2>
          <Form noValidate onSubmit={handleMemberSearch} className='form-inline'>
            <Form.Group>
              <Form.Label style={{ margin: '0 1em 0 0' }}>Find a Member</Form.Label>
              <Form.Control
                as='select'
                name=''
                style={{ margin: '0 1em 0 0' }}
                value={formState.category}
                onChange={(e: any) => setFormState((ps) => ({ ...ps, category: e.target.value }))}
              >
                <option disabled>Select Category</option>
                <option value='LastName'>Last Name</option>
                <option value='MemberId'>Member ID</option>
                {
                  appConfig.use_member_search_policy_number && <option value='PolicyNumber'>Policy Number</option>
                }
              </Form.Control>
              <Form.Control
                style={{ margin: '0 1em 0 0' }}
                type='text'
                name='keyword'
                placeholder="Enter Search Terms"
                value={formState.keyword}
                onChange={(e: any) => setFormState((ps) => ({ ...ps, keyword: e.target.value }))}
              />
            </Form.Group>
            <Button className='custom mr-2' type='submit' disabled={formState.keyword === ''}>
              Search
            </Button>
            <Button className='outline-searchBtn' onClick={handleClearSearch}>
              Clear
            </Button>
          </Form>
          <hr />
          <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
            {
              members?.members.length > 0 && (
                <div className='mb-3'>
                  <PaginationButtons
                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    handleChangePage={handleChangePage}
                  />
                </div>

              )
            }
            {
              members?.total > 0 && `${members.total} record(s) returned`
            }
          </div>

          {
            isError && <Alert className='mt-3' variant='danger' >We received an error finding your members.</Alert>
          }
          <Table hover size='sm' className='mt-3'>
            <thead>
              <tr>
                <th scope="col">Most Recent Member ID</th>
                <th scope="col">Name</th>
                <th scope="col">FHIR Data</th>
                <th scope='col'>Account ID</th>
                <th scope="col">Manage Account</th>
                <th scope='col'>Account Status</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading || isFetching ? (
                  <tr>
                    <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                      <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                      />
                    </td>
                  </tr>
                ) :
                  isSuccess && members.members.length > 0 ? (
                    <>
                      {
                        members.members.map((member: any) => (
                          <tr key={member.memberId}>
                            <th>{member.memberId}</th>
                            <td>{member.firstName || ''} {member.lastName || ''}</td>
                            <td><Link to={'/patient'} onClick={() => setFhirId(member.patientId)}>View Data</Link></td>
                            <td>
                              {member.patientId}
                            </td>
                            <td>
                              {
                                member.hasAccount && (
                                  <Link to={`/account/${member.accountId}`}>Manage Account</Link>
                                )
                              }
                            </td>
                            <td>
                              {
                                member.hasAccount ?
                                  member.enabled ? 'Active' : 'Locked' : ''
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </>
                  ) : isSuccess && members.members.length === 0 ? (
                    (
                      <tr>
                        <td scope="row" colSpan={8} style={{ backgroundColor: '#e3e3e3', textAlign: 'center' }}>
                          <span>No records found.</span>
                        </td>
                      </tr>
                    )
                  ) : null
              }
            </tbody>
            <tfoot>
              <div className='d-flex flex-column mt-3 font-primary-color font-weight-bold'>
                {
                  members?.members.length > 0 && (
                    <tr>
                      <td scope="row" colSpan={6}>
                        <PaginationButtons
                          onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                          onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          pageLinks={pageLinks}
                          handleChangePage={handleChangePage}
                        />
                      </td>
                    </tr>
                  )
                }
              </div>
            </tfoot>
          </Table>
        </Container>
      </PermissionGate>
    </main>
  )
}

export default isAuthenticated(Members)