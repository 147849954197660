import React, { FC } from 'react'
import CarePlanList from './CarePlanList'
import CarePlanDetail from './CarePlanDetail'
import { CarePlanBundle } from '../../../../types/FHIRTypes/Bundle'

interface Props {
  patientData: any;
  carePlanData: CarePlanBundle;
  carePlanDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
  currentPage: number;
  pageLinks: { page: number; url: string }[];
}

const CarePlans: FC<Props> = ({ patientData, carePlanData, carePlanDetailId, page, isFetching, handleChangePage, totalPages, currentPage, pageLinks}) => {
  return (
    <div>
      {
        carePlanDetailId && page === 'CarePlan'
          ? (
            <CarePlanDetail
              carePlanDetailId={carePlanDetailId}
              carePlanData={carePlanData}
              isFetching={isFetching}
            />
          )
          : (
            <CarePlanList
              carePlanData={carePlanData}
              handleChangePage={handleChangePage}
              isFetching={isFetching}
              totalPages={totalPages}
              currentPage={currentPage}
              pageLinks={pageLinks}
            />
          )
      }
    </div>
  )
}
export default CarePlans