import React, { FC } from 'react'
import { URI as FhirURI } from '../../../../types/FHIRTypes/URI'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface URIProps {
  data?: FhirURI | undefined
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const URI: FC<URIProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (dataExtension) {
      return <DataAbsentReason dataExtension={dataExtension} />
  }
  const getURIDisplay = (): string => {        
      return data || ''
  }
    
  return (
    <div>{getURIDisplay()}</div>
  )
}

export default URI