import React, { FC } from 'react'
import ImmunizationList from './ImmunizationList'
import ImmunizationDetail from './ImmunizationDetail'
import { ImmunizationBundle } from '../../../../types/FHIRTypes/Bundle'

interface Props {
  immunizationData: ImmunizationBundle;
  patientData: any;
  immunizationDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}

const Immunizations: FC<Props> = ({ immunizationData, patientData, immunizationDetailId, page, isFetching, handleChangePage, totalPages, currentPage, pageLinks }: Props) => {
  // const immunizationData = formatImmunizationDataForTable(immunizationData, buildPatientId(patientData))
  return (
    <div>
      {
        immunizationDetailId && page === 'Immunization' ? (
          <ImmunizationDetail detailId={immunizationDetailId} immunizationData={immunizationData} patientData={patientData} isFetching={isFetching} />
        ) : <ImmunizationList immunizationData={immunizationData} patientData={patientData} isFetching={isFetching} handleChangePage={handleChangePage} totalPages={totalPages} currentPage={currentPage}pageLinks={pageLinks}
       />
      }
    </div>
  )
}
export default Immunizations