import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { handleReference, handleExtension } from '../../../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import { MedicationRequest } from '../../../../../types/FHIRTypes/MedicationRequest'
import ProvenanceDetail from '../../Provenance'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../../types/FieldConfig'
import Narrative from '../../../DataTypes/Narrative'
import Identifier from '../../../DataTypes/Identifier'
import CapitalizedText from '../../../DisplayComponents/CapitalizedText'
import Medication from '../../../DisplayComponents/Medication'
import MedDispenseRequest from '../../../DisplayComponents/MedDispenseRequest'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import Simple from '../../../DisplayComponents/Simple'
import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import Dosage from '../../../DataTypes/Dosage'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import DateTime from '../../../DataTypes/DateTime'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'

interface MedicationDetailProps {
    medicationDetailId: any;
    medicationData: any;
    isFetching: boolean;
    patientData: any
}

const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    medication: { label: 'Medication', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    statusReason: { label: 'Status Reason', visibility: 'conditional' },
    intent: { label: 'Intent Code', visibility: 'always' },
    category: { label: 'Category', visibility: 'conditional' },
    encounter: { label: 'Encounter', visibility: 'always' },
    authoredOn: { label: 'Date When Written', visibility: 'always' },
    requester: { label: 'Prescriber', visibility: 'always' },
    dosageInstruction: { label: 'Dosage Instruction', visibility: 'always' },
    dispenseRequest: { label: 'Dispense Request', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    //metadata
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
    reported: { label: 'Record Not Primary Source', visibility: 'conditional' },
}



const MedicationDetail: React.FC<MedicationDetailProps> = ({ medicationDetailId, medicationData, isFetching, patientData }) => {
    const [resource, setResource] = useState<MedicationRequest>({} as MedicationRequest)

    const history = useHistory()
    useEffect(() => {
        if (!medicationData) return

        const foundMedication = medicationData.entry.find((a: any) => a.resource.id === medicationDetailId)
        if (foundMedication) {
            setResource(foundMedication.resource)
        } else {
            history.push('/404')
        }
    }, [medicationData])

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                Loading Medication...
            </div>
        )
    }
    return (
        <>
            <dl className='dataContainer'>
                {resource && (
                    <>
                        <Row>
                            <ShowField field='subject' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.subject.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><PatientReference patient={resource.subject} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='medication' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.medication.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        <Medication medication={resource} />
                                    </dd>
                                </Col>
                            </ShowField>

                            <ShowField field='status' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.status.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='statusReason' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.statusReason.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><CodeableConcept data={resource.statusReason} dataExtension={resource._statusReason} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='intent' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.intent.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Code data={resource.intent} dataExtension={resource._intent} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='category' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.category.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='encounter' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.encounter.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Reference data={resource.encounter} dataExtension={resource._encounter} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='authoredOn' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.authoredOn.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><DateTime data={resource.authoredOn} dataExtension={resource._authoredOn} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='requester' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.requester.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {resource.requester?.reference
                                            ? handleReference(resource.requester)
                                            : resource.requester?.extension ? handleExtension(resource.requester.extension) : ''}
                                    </dd>

                                    <dd>{handleReference(resource.requester)}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='dosageInstruction' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.dosageInstruction.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {resource.dosageInstruction?.map((dosage, index) => (
                                            <Dosage key={index} dosageInstruction={dosage} />
                                        ))}
                                    </dd>
                                </Col>
                            </ShowField>

                            <ShowField field='dispenseRequest' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.dispenseRequest.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><MedDispenseRequest medDispenseRequest={resource} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='text' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Narrative data={resource.text} dataExtension={resource._text} /></dd>
                                </Col>
                            </ShowField>
                            <Col sm={12} className='footer'>
                                <hr />
                                <h6>FHIR Resource Metadata</h6>
                                <Row>
                                    <ShowField field='resourceType' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.resourceType.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='id' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.id.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd><StringDisplay data={resource?.id} dataExtension={resource?._id} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='meta' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.meta.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd><MetaLastUpdated meta={resource.meta} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='profile' config={config} resource={resource.meta}>
                                        <Col sm={3}>
                                            <dt>{config.profile.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>
                                                {<Profile profile={resource.meta?.profile} />}
                                            </dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='language' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.language.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='implicitRules' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.implicitRules.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                        </Col>
                                    </ShowField>
                                    <ShowField field='identifier' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.identifier.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>
                                                <Identifier identifiers={resource.identifer} />
                                            </dd>
                                        </Col >
                                    </ShowField >
                                    <ShowField field='reported' config={config} resource={resource}>
                                        <Col sm={3}>
                                            <dt>{config.reported.label}</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>
                                                <CapitalizedText
                                                    text={resource.reportedBoolean !== undefined ? resource.reportedBoolean.toString() : resource.reportedReference?.reference ?? ''}
                                                />
                                            </dd>
                                        </Col>
                                    </ShowField>
                                </Row >
                            </Col >
                            <Col sm={12}>
                                <ProvenanceDetail resourceName='MedicationRequest' resourceId={medicationDetailId} />
                            </Col>
                        </Row >
                    </>
                )}
            </dl >
        </>
    )
}

export default MedicationDetail
