import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'
import Medication from '../../../DisplayComponents/Medication'
import Code from '../../../DataTypes/Code'
import DateTime from '../../../DataTypes/DateTime'
import PractitionerReference from '../../../DisplayComponents/PractitionerReference'

interface MedicationsProps {
    medicationData: any;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}


const MedicationList: React.FC<MedicationsProps> = ({ medicationData, isFetching, handleChangePage, totalPages, currentPage, pageLinks }) => {
    const requestedMedications = medicationData && medicationData.entry ? medicationData.entry.map((medication: any) => medication.resource) : []

    const nextLink = medicationData && medicationData.link.find((l: any) => l.relation === 'next')
    const previousLink = medicationData && medicationData.link.find((l: any) => l.relation === 'previous')

    return (
        <>
            {medicationData?.total > 0 && (
                <div className="mb-2">
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {`${medicationData.total.toLocaleString()} record(s) found`}
                    </div>
                    <PaginationButtons
                        onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                        onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        pageLinks={pageLinks}
                        handleChangePage={handleChangePage}
                    />
                </div>
            )}
            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Medication</th>
                        <th className='d-table-cell'>Intent Code</th>
                        <th className='d-table-cell'>Status</th>
                        <th className='d-table-cell'>Date When Written</th>
                        <th className='d-none d-lg-table-cell'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='d-flex'>
                                    Loading Member Medications...
                                </td>
                            </tr>
                        ) : requestedMedications?.length === 0 ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='text-center'>
                                    No Medications found for this member.
                                </td>
                            </tr>
                        ) : (
                            <>
                                {
                                    requestedMedications.map((medication: any) => (
                                        <tr key={medication.id}>
                                            <td className='d-table-cell'><Link to={`/patient/MedicationRequest/${medication.id}`}><Medication medication={medication} /></Link></td>
                                            <td className='d-table-cell'><Code data={medication.intent} dataExtension={medication._intent} /></td>
                                            <td className='d-table-cell'><Code data={medication.status} dataExtension={medication._status} /></td>
                                            <td className='d-table-cell'><DateTime data={medication.authoredOn} dataExtension={medication._authoredOn} /></td>
                                            <td className='d-none d-lg-table-cell'><Link to={`/patient/MedicationRequest/${medication.id}`}>Detail</Link></td>
                                        </tr>
                                    ))
                                }
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                    {medicationData?.total > 0 &&
                        <tr>
                            <td scope="row" colSpan={5}>
                                <PaginationButtons
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    pageLinks={pageLinks}
                                    handleChangePage={handleChangePage}
                                />
                            </td>
                        </tr>
                    }
                    </div>
                </tfoot>
            </Table>
        </>
    )
}

export default MedicationList