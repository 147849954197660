import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface BooleanProps {
  data?: boolean
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Boolean: FC<BooleanProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  return (
    <span>
      {data !== undefined ? data.toString() : <DataAbsentReason dataExtension={dataExtension} />}
    </span>
  )
}

export default Boolean