import React, { FC } from 'react'
import { Period as FhirPeriod } from '../../../../types/FHIRTypes/Period'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { formatDateWithFormat } from '../../../../utils/helpers'
import DataAbsentReason from '../DataAbsentReason'

interface FhirPeriodProps {
    data?: FhirPeriod
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Period: React.FC<FhirPeriodProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    const periodStart = data?.start ? formatDateWithFormat(data.start ?? '', 'MM/dd/yyyy') + ';' : ''
    const periodEnd = data?.end ? formatDateWithFormat(data.end ?? '', 'MM/dd/yyyy') + ';' : ''

    const startCaption = data?.start ? 'Start: ' : ''
    const endCaption = data?.end ? 'End: ' : ''


    return (
        <div>{`${startCaption}${periodStart} ${endCaption}${periodEnd}`}</div>
    )
}

export default Period