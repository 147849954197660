import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ProviderResource } from '../../../../../types/ProviderResource'
import { capitalizeFirstLetter } from '../../../../../utils/helpers'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Identifier from '../../../DataTypes/Identifier'
import Boolean from '../../../DataTypes/Boolean'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import StringDisplay from '../../../DataTypes/String'
import Address from '../../../DataTypes/Address'
import Reference from '../../../DataTypes/Reference'
import ContactPoint from '../../../DataTypes/ContactPoint'
import ProvenanceDetail from '../../Provenance'
import URI from '../../../DataTypes/URI'
import Code from '../../../DataTypes/Code'
import Profile from '../../../DisplayComponents/Profile'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import ResourceType from '../../../DisplayComponents/ResourceType'
import Narrative from '../../../DataTypes/Narrative'
import { Organization } from '../../../../../types/FHIRTypes/Organization'
import LocationReference from '../../../DisplayComponents/LocationReference'
import { Extension } from '../../../../../types/FHIRTypes/Extension'

interface NetworksDetailProps {
    networks: Organization | null
}

const config: FieldConfig = {
    identifier: { label: 'Network Identifier', visibility: 'always' },
    active: { label: 'Active', visibility: 'always' },
    type: { label: 'Type', visibility: 'always' },
    name: { label: 'Name', visibility: 'always' },
    alias: { label: 'Alias', visibility: 'conditional' },
    address: { label: 'Address', visibility: 'always' },
    partOf: { label: 'Part Of', visibility: 'conditional' },
    contact: { label: 'Network Contacts', visibility: 'conditional' },
    extension: { label: 'Network Coverage Area', visibility: 'always' },
    endpoint: { label: 'Endpoint', visibility: 'always' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const NetworkDetail: React.FC<NetworksDetailProps> = ({ networks }) => {

    const filterExtensionsByUrl = (extensions: Extension[] | undefined, url: string): Extension[] => {
        return extensions?.filter((ext: Extension) => ext.url === url) || []
    }

    return (
        <section>
            <h3>Networks</h3>
            <dl className='dataContainer'>
                <Row>
                    <ShowField field='identifier' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.identifier.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Identifier identifiers={networks?.identifier} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='active' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.active.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                <Boolean
                                    data={networks?.active} dataExtension={networks?._active}
                                />
                            </dd>
                        </Col>

                    </ShowField>
                    <ShowField field='type' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.type.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><CodeableConcept data={networks?.type} dataExtension={networks?._type} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field="name" config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.name.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><StringDisplay data={networks?.name} dataExtension={networks?._name} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='alias' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.alias.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><StringDisplay data={networks?.alias} dataExtension={networks?._alias} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='address' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.address.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {networks?.address &&
                                    networks.address.map((addr, index) => (
                                        <Address key={index} address={addr} />
                                    ))}
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='partOf' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.partOf.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                <Reference
                                    data={networks?.partOf} />
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='contact' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.contact.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {networks?.contactPoint && <ContactPoint contactPoint={networks.contactPoint} />}
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='extension' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.extension.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {filterExtensionsByUrl(
                                    networks?.extension,
                                    'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/location-reference'
                                ).map((ext, index) => (
                                    <LocationReference key={index} location={ext.valueReference} />
                                ))}
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='endpoint' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.endpoint.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Reference data={networks?.endpoint} dataExtension={networks?._endpoint} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='text' config={config} resource={networks}>
                        <Col sm={3}>
                            <dt>{config.text.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Narrative data={networks?.text} dataExtension={networks?._text} /></dd>
                        </Col>
                    </ShowField>
                    <Col sm={12} className='footer'>
                        <hr />

                        <h6>FHIR Resource Metadata</h6>
                        <Row>
                            <ShowField field='resourceType' config={config} resource={networks}>
                                <Col sm={3}>
                                    <dt>{config.resourceType.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{<ResourceType resourceType={networks?.resourceType} />}</dd>
                                </Col>
                            </ShowField>
                            <ShowField field='id' config={config} resource={networks}>
                                <Col sm={3}>
                                    <dt>{config.id.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><StringDisplay data={networks?.id} dataExtension={networks?._id} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='meta' config={config} resource={networks}>
                                <Col sm={3}>
                                    <dt>{config.meta.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        <MetaLastUpdated meta={networks?.meta} />
                                    </dd>
                                </Col>
                            </ShowField>
                            <ShowField field='profile' config={config} resource={networks?.meta}>
                                <Col sm={3}>
                                    <dt>{config.profile.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{<Profile profile={networks?.meta?.profile} />}</dd>
                                </Col>
                            </ShowField>
                            <ShowField field='language' config={config} resource={networks}>
                                <Col sm={3}>
                                    <dt>{config.language.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Code
                                        data={networks?.language} dataExtension={networks?._language}
                                        /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='implicitRules' config={config} resource={networks}>
                                <Col sm={3}>
                                    <dt>{config.implicitRules.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><URI data={networks?.implicitRules} dataExtension={networks?._implicitRules} /></dd>
                                </Col>
                            </ShowField>



                        </Row>
                    </Col>
                </Row>
            </dl>
        </section>
    )
}

export default NetworkDetail