import React, { FC } from 'react'
import ConditionList from './ConditionsList'
import ConditionDetail from './ConditionsDetail'

interface Props {
    conditionData: any;
    patientData: any;
    conditionDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}


const Conditions: FC<Props> = ({ conditionData, patientData, conditionDetailId, page, isFetching, handleChangePage,totalPages, currentPage, pageLinks }: Props) => {
    return (
        <div>
            {
                conditionDetailId && page === 'Condition' ? (
                    <ConditionDetail
                        conditionData={conditionData}
                        isFetching={isFetching}
                        conditionDetailId={conditionDetailId}
                    />
                ) :
                    <ConditionList
                        conditionData={conditionData}
                        patientData={patientData}
                        isFetching={isFetching}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        pageLinks={pageLinks}
                    />
            }
        </div>
    )
}

export default Conditions