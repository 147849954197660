import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

interface Payload {
    resource?: string;
    pagesOffset?: string;
    pageState?: string;
    resourceId?: string | undefined;
    searchTerms?: string | undefined;
    pageSize?: number;
}

export default function useProviderFhirResource(payload: Payload): any {
    
    return useQuery(['provider-resource-all', payload.resource, payload.pagesOffset], async () => {
        return await request.get(`${getApiServer()}/fhir/provider-resource`).query({ ...payload }).set('Accept', 'application/json').withCredentials().then((res) => res.body)
    }, {
        staleTime: Infinity,
        cacheTime: 5 * 60 * 1000,
        retry: 0,
        keepPreviousData: true,
        enabled: false
    })

}