import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import HumanName from '../../DataTypes/HumanName'
import { Patient } from '../../../../types/FHIRTypes/Patient'
import { UseQueryResult } from 'react-query'
import Reference from '../../DataTypes/Reference'
import ReferencePopover from '../../../ReferencePopover'
import { getHumanName } from '../../../../utils/fhirUtils/getHumanName'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../../DisplayComponents/FieldVisibilityWrapper'
import StringDisplay from '../../DataTypes/String'
import { isReference } from '../../../../typeGuards/fhirTypes'
import Extension from '../../DataTypes/Extension'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'

interface PatientReferenceProps {
    patient?: FHIRReference
}

const patientFieldConfig: FieldConfig = {
    resourceId: { label: 'ID', visibility: 'always' },
    value: { label: 'Value', visibility: 'always' }
}

const PatientReference: React.FC<PatientReferenceProps> = ({ patient }) => {
    if (!patient) return null

    if (!isReference(patient)) {
        return <Extension nestedExtension={patient as CodeableConcept} />
    }

    const reference = patient?.reference?.split('/')
    const patientId = reference?.[1] || ''

    const {
        data: patientData,
        refetch: getPatient,
        isFetching: fetchingPatient,
        isLoading: loadingPatient
    }: UseQueryResult<Patient, Error> = useFhirResourceById(patientId, 'Patient')

    useEffect(() => {
        if (patient) {
            getPatient()
        }
    }, [patient])

    if (fetchingPatient || loadingPatient) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    const { name } = patientData || {}

    return (
        <div className="d-flex">
            {name ? (
                <div className="d-flex align-items-center">
                    <HumanName name={name[0]} />
                    <ReferencePopover
                        title={getHumanName(name[0])}
                    >
                        <ShowField field='resourceId' config={patientFieldConfig} resource={patientData}>
                            <StringDisplay data={patientData?.id} />
                        </ShowField>
                        <ShowField field='value' config={patientFieldConfig} resource={patientData}>
                            <Reference data={patient} />
                        </ShowField>
                    </ReferencePopover>
                </div>
            ) : (
                <Reference data={patient} />
            )}
        </div>
    )
}

export default PatientReference