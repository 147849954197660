import React from 'react'
import { EncounterBundle } from '../../../../types/FHIRTypes/Bundle'
import EncounterList from './EncounterList'
import { EncounterEntry } from '../../../../types/FHIRTypes/Entry'
import EncounterDetail from './EncounterDetail'

interface EncountersProps {
    patientData: any;
    page: string | undefined;
    isFetching: boolean;
    encounterDetailId: string | undefined;
    encounterData: EncounterBundle | undefined;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}

const Encounters: React.FC<EncountersProps> = ({ patientData, encounterDetailId, page, encounterData, isFetching, handleChangePage, totalPages, currentPage, pageLinks}) => {
    const encounters = (encounterData?.entry?.filter((entry) => entry.resource.resourceType === 'Encounter') || []) as EncounterEntry[]

    return (
        <>
            {
                encounterDetailId && page === 'Encounter' ? (
                    <EncounterDetail
                        encounters={encounters}
                        encounterDetailId={encounterDetailId}
                        isFetching={isFetching}
                        dataExists={encounterData !== undefined}
                        patientData={patientData}
                    />
                ) :
                    <EncounterList
                        data={encounterData}
                        encounters={encounters}
                        isFetching={isFetching}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        pageLinks={pageLinks}
                    />
            }
        </>
    )
}

export default Encounters