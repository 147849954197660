import React from 'react'
import ProcedureDetail from './ProcedureDetail'
import ProcedureList from './ProcedureList'
import { ProcedureBundle } from '../../../../types/FHIRTypes/Bundle'

interface ProcedureProps {
    patientData: any;
    page: string | undefined;
    isFetching: boolean;
    procedureData: ProcedureBundle;
    procedureDetailId: string | undefined;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}

const Procedure: React.FC<ProcedureProps> = ({ patientData, procedureDetailId, procedureData, isFetching, page, handleChangePage, totalPages, currentPage, pageLinks }) => {
    return (
        <>
            {
                procedureDetailId && page === 'Procedure' ? (
                    <ProcedureDetail
                        procedureData={procedureData}
                        isFetching={isFetching}
                        procedureDetailId={procedureDetailId} />
                ) : <ProcedureList
                    procedureData={procedureData}
                    handleChangePage={handleChangePage}
                    isFetching={isFetching}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                />
            }
        </>
    )
}

export default Procedure