import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CareTeamBundle } from '../../../../../types/FHIRTypes/Bundle'
import { formatCareTeamDataForTable } from '../../../../../utils/fhirUtils/careTeam'
import PaginationButtons from '../../../../PaginationButtons'
import Code from '../../../DataTypes/Code'
import Period from '../../../DataTypes/Period'
import { ca } from 'date-fns/locale'

interface Props {
  careTeamData: CareTeamBundle;
  isFetching: boolean;
  handleChangePage: (page: number | string) => void
  totalPages: number;
  currentPage: number;
  pageLinks: { page: number; url: string }[];
}


const CareTeamList: FC<Props> = ({ careTeamData, handleChangePage, isFetching, totalPages, currentPage, pageLinks }: Props) => {
  const patientCareTeams = formatCareTeamDataForTable(careTeamData)
  const nextLink = careTeamData?.link?.find((l: any) => l.relation === 'next')
  const previousLink = careTeamData?.link?.find((l: any) => l.relation === 'previous')
  return (
    <>
      <div className="d-flex flex-column mb-3 font-primary-color font-weight-bold">
        {patientCareTeams.length > 0 && (
          <div className="mb-2">
            <PaginationButtons
              onBackClick={() => previousLink && handleChangePage(previousLink.url)}
              onNextClick={() => nextLink && handleChangePage(nextLink.url)}
              totalPages={totalPages}
              currentPage={currentPage}
              pageLinks={pageLinks}
              handleChangePage={handleChangePage}
            />
          </div>
        )}
        {careTeamData?.total > 0
          ? `${careTeamData.total.toLocaleString()} record(s) found`
          : ''}
      </div>
      <Table size="sm" hover>
        <thead>
          <tr>
            <th className="d-table-cell">Care Team Status</th>
            <th className="d-none d-lg-table-cell">Period</th>
            <th className="d-none d-lg-table-cell">Detail</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td colSpan={6} rowSpan={15} className="d-flex">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
                <span style={{ marginLeft: '10px' }}>
                  Loading Care Team Data...
                </span>
              </td>
            </tr>
          ) : patientCareTeams.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center">
                No Care Teams found for this member.
              </td>
            </tr>
          ) : (
            patientCareTeams.map((careTeam: any) => (
              <tr key={careTeam.careTeamId}>
                <td className='d-table-cell'><Link to={`/patient/CareTeam/${careTeam.careTeamId}`}><Code data={careTeam.status} dataExtension={careTeam._status} /></Link></td>
                <td className='d-table-cell'> <Period data={careTeam.period} dataExtension={careTeam._period} /></td>
                <td className="d-none d-lg-table-cell">
                  <Link to={`/patient/CareTeam/${careTeam.careTeamId}`}>
                    Detail
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>

        <tfoot>
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {patientCareTeams.length > 0 && (
              <tr>
                <td scope="row" colSpan={5}>
                  <PaginationButtons
                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    handleChangePage={handleChangePage}
                  />
                </td>
              </tr>
            )}
           </div>
        </tfoot>
      </Table>
    </>
  )
}

export default CareTeamList