import React from 'react'
import { Col, Row } from 'react-bootstrap'
import OrganizationDescription from '../../../DisplayComponents/OrganizationDescription'
import { Organization } from '../../../../../types/FHIRTypes/Organization'
import Identifier from '../../../DataTypes/Identifier'
import Boolean from '../../../DataTypes/Boolean'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import StringDisplay from '../../../DataTypes/String'
import ContactPoint from '../../../DataTypes/ContactPoint'
import OrganizationReference from '../../../DisplayComponents/OrganizationReference'
import Reference from '../../../DataTypes/Reference'
import Narrative from '../../../DataTypes/Narrative'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import ResourceType from '../../../DisplayComponents/ResourceType'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import Address from '../../../DataTypes/Address'

interface OrganizationDetailProps {
    organization: Organization | null
}

const config: FieldConfig = {
    identifier: { label: 'Organization Identifier', visibility: 'always' },
    active: { label: 'Active', visibility: 'always' },
    type: { label: 'Type', visibility: 'always' },
    name: { label: 'Name', visibility: 'always' },
    alias: { label: 'Alias', visibility: 'conditional' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    address: { label: 'Address', visibility: 'always' },
    partOf: { label: 'Part Of', visibility: 'conditional' },
    contact: { label: 'Organization Contacts', visibility: 'conditional' },
    endpoint: { label: 'Endpoint', visibility: 'always' },
    extension: { label: 'Organization Description', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
}

const OrganizationDetail: React.FC<OrganizationDetailProps> = ({ organization }) => {

    return (
        <section>
            <h3>Organizations</h3>
            <dl className='dataContainer'>
                <Row>
                    <ShowField field='identifier' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.identifier.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Identifier identifiers={organization?.identifier} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='active' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.active.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Boolean data={organization?.active} dataExtension={organization?._active} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='type' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.type.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {organization?.type && organization.type.length > 0 &&
                                    organization.type.map((type, index) => (
                                        <td key={`type-${index}`}>
                                            <CodeableConcept data={type} dataExtension={organization?._type} />
                                        </td>
                                    ))
                                }
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field="name" config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.name.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><StringDisplay data={organization?.name} dataExtension={organization?._name} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='alias' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.alias.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><StringDisplay data={organization?.alias} dataExtension={organization?._alias} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='telecom' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.telecom.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {organization?.telecom?.length && organization.telecom.length > 0 &&
                                    organization.telecom.map((contact, index) => (
                                        <ContactPoint key={`telecom-${index}`} contactPoint={contact} />
                                    ))
                                }
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='address' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.address.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {organization?.address?.length && organization?.address?.length > 0 && organization.address.map((addr, index) => (
                                    <Address key={`address-${index}`} address={addr} />
                                ))}

                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='partOf' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.partOf.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <OrganizationReference organization={organization?.partOf}></OrganizationReference>
                        </Col>
                    </ShowField>
                    <ShowField field='contact' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.contact.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>
                                {organization?.contact?.map((contact, index) => (
                                    contact.telecom?.map((telecom, telecomIndex) => (
                                        <ContactPoint key={`${index}-${telecomIndex}`} contactPoint={telecom} />
                                    ))
                                ))}
                            </dd>
                        </Col>
                    </ShowField>
                    <ShowField field='endpoint' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.endpoint.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Reference data={organization?.endpoint} dataExtension={organization?._endpoint} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='extension' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.extension.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><OrganizationDescription extension={organization?.extension} /></dd>
                        </Col>
                    </ShowField>
                    <ShowField field='text' config={config} resource={organization}>
                        <Col sm={3}>
                            <dt>{config.text.label}</dt>
                        </Col>
                        <Col sm={9}>
                            <dd><Narrative data={organization?.text} dataExtension={organization?._text} /></dd>
                        </Col>
                    </ShowField>
                    <Col sm={12} className='footer'>
                        <hr />

                        <h6>FHIR Resource Metadata</h6>
                        <Row>
                            <ShowField field='resourceType' config={config} resource={organization}>
                                <Col sm={3}>
                                    <dt>{config.resourceType.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><ResourceType resourceType={organization?.resourceType} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='id' config={config} resource={organization}>
                                <Col sm={3}>
                                    <dt>{config.id.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><StringDisplay data={organization?.id} dataExtension={organization?._id} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='meta' config={config} resource={organization}>
                                <Col sm={3}>
                                    <dt>{config.meta.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        <MetaLastUpdated meta={organization?.meta} />
                                    </dd>
                                </Col>
                            </ShowField>
                            <ShowField field='profile' config={config} resource={organization?.meta}>
                                <Col sm={3}>
                                    <dt>{config.profile.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Profile profile={organization?.meta?.profile} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='language' config={config} resource={organization}>
                                <Col sm={3}>
                                    <dt>{config.language.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Code
                                        data={organization?.language} dataExtension={organization?._language}
                                    /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='implicitRules' config={config} resource={organization}>
                                <Col sm={3}>
                                    <dt>{config.implicitRules.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><URI data={organization?.implicitRules} dataExtension={organization?._implicitRules} /></dd>
                                </Col>
                            </ShowField>
                        </Row>
                    </Col>
                </Row>
            </dl>
        </section>
    )
}

export default OrganizationDetail