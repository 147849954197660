import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface TimeProps {
    data?: string
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const TimeDisplay: FC<TimeProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
      return null
    }
  
    if (dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    if (data) {
        return <div>{data}</div>
    }

    return null
}
export default TimeDisplay