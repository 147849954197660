import React from 'react'
import { EncounterEntry } from '../../../../../types/FHIRTypes/Entry'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Encounter } from '../../../../../types/FHIRTypes/Encounter'
import { EncounterBundle } from '../../../../../types/FHIRTypes/Bundle'
import PaginationButtons from '../../../../PaginationButtons'
import Code from '../../../DataTypes/Code'
import Coding from '../../../DataTypes/Coding'
import CodeableConcept from '../../../DataTypes/CodeableConcept'

interface EncounterListProps {
    data: EncounterBundle | undefined;
    encounters: EncounterEntry[];
    isFetching: boolean;
    handleChangePage: (page: number | string) => void
    totalPages: number;
    currentPage: number;
    pageLinks: { page: number; url: string }[];
}

const EncounterList: React.FC<EncounterListProps> = ({ encounters, isFetching, data, handleChangePage, totalPages, currentPage, pageLinks }) => {

    const getEncounterReason = (encounter: Encounter) => {
        <CodeableConcept data={encounter.reasonCode} dataExtension={encounter._reasonCode} />

        // Return a more meaningful default value
        return 'Reason not specified'
    }

    const nextLink = data && data.link.find((l: any) => l.relation === 'next')
    const previousLink = data && data.link.find((l: any) => l.relation === 'previous')

    return (
        <>
            {
                data && data.total > 0 && (
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {data.total > 0 &&
                            <div className='mb-2'>
                                <PaginationButtons
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    pageLinks={pageLinks}
                                    handleChangePage={handleChangePage}
                                />
                            </div>
                        }
                        {`${data.total.toLocaleString()} record(s) found`}
                    </div>
                )
            }
            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Status</th>
                        <th className='d-none d-lg-table-cell'>Classification</th>
                        <th className='d-none d-lg-table-cell'>Encounter Type</th>
                        <th className='d-none d-lg-table-cell'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='d-flex'>
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                    Loading Member Encounters...
                                </td>
                            </tr>
                        ) : data && encounters.length === 0 ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='text-center'>
                                    No Encounters found for this member.
                                </td>
                            </tr>
                        ) : (
                            <>
                                {
                                    encounters.map((encounter) => (
                                        <tr key={encounter.resource.id}>
                                            <td className='d-table-cell'><Link to={`/patient/Encounter/${encounter.resource.id}`}><Code data={encounter.resource.status} dataExtension={encounter.resource._status} /></Link></td>
                                            <td className='d-none d-lg-table-cell'><Coding data={encounter.resource.class} dataExtension={encounter.resource._class} /></td>
                                            <td className='d-none d-lg-table-cell'><CodeableConcept data={encounter.resource.type} dataExtension={encounter.resource._type} /></td>
                                            <td className='d-none d-lg-table-cell'>
                                                <Link to={`/patient/Encounter/${encounter.resource.id}`}>
                                                    Detail
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {data && data.total > 0 &&
                            <tr>
                                <td scope="row" colSpan={5}>
                                    <PaginationButtons
                                        onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                        onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        pageLinks={pageLinks}
                                        handleChangePage={handleChangePage}
                                    />
                                </td>
                            </tr>
                        }
                    </div>
                </tfoot>
            </Table >
        </>
    )
}

export default EncounterList