import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Identifier from '../../../DataTypes/Identifier'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import Simple from '../../../DisplayComponents/Simple'
import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'

interface Props {
    conditionDetailId: any;
    conditionData: any;
    isFetching: boolean;
}


const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    clinicalStatus: { label: 'Clinical Status', visibility: 'always' },
    verificationStatus: { label: 'Verification Status', visibility: 'always' },
    category: { label: 'Category', visibility: 'always' },
    code: { label: 'Code', visibility: 'always' },
    onset: { label: 'Onset Date Time', visibility: 'conditional' },
    abatement: { label: 'Abatement ', visibility: 'conditional' },
    severity: { label: 'Severity', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },
    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'always' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const ConditionDetail: FC<Props> = ({ conditionDetailId, conditionData, isFetching }: Props) => {
    const [resource, setResource] = useState<any>(null)

    const history = useHistory()

    useEffect(() => {
        if (!conditionData) return

        const foundCondition = conditionData.entry.find((condition: any) => condition.resource.id === conditionDetailId)
        if (foundCondition) {
            setResource(foundCondition.resource)
        } else {
            history.push('/404')
        }
    }, [conditionData])


    return (
        isFetching ? (
            <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Member Diagnoses...</span>
                </td>
            </tr>
        ) : resource ? (
            <>
                <dl className='dataContainer'>
                    <Row>
                        <ShowField field='subject' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.subject.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><PatientReference patient={resource.subject} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='clinicalStatus' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.clinicalStatus.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><CodeableConcept data={resource?.clinicalStatus} dataExtension={resource._clinicalStatus} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='verificationStatus' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.verificationStatus.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><CodeableConcept data={resource?.verificationStatus} dataExtension={resource._verificationStatus} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='category' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.category.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='code' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.code.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><CodeableConcept data={resource.code} dataExtension={resource._code} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='onset' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.onset.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><Simple simple={resource.onsetDateTime} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='abatement' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.abatement.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><Simple simple={resource.abatement} /></dd>
                            </Col>
                        </ShowField>

                        <ShowField field='severity' config={config} resource={resource}>
                            <Col sm={3}>
                                <dt>{config.severity.label}</dt>
                            </Col>
                            <Col sm={9}>
                                <dd><CodeableConcept data={resource.severity} dataExtension={resource._severity} /></dd>
                            </Col>
                        </ShowField>

                        <Col sm={12} className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><StringDisplay data={resource.id} dataExtension={resource._id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            <MetaLastUpdated meta={resource.meta} />
                                        </dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={resource.meta}>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<Profile profile={resource.meta.profile} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><Code data={resource.language} dataExtension={resource._language}/></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                         <dd><URI data={resource.implicitRules} dataExtension={resource._implicitRules} /></dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='identifier' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <Identifier identifiers={resource.identifier} />
                                    </Col>
                                </ShowField>
                            </Row>
                        </Col>

                        <Col sm={12}>
                            <ProvenanceDetail resourceName='Condition' resourceId={conditionDetailId} />
                        </Col>
                    </Row>
                </dl>
            </>

        ) :
            <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                    No Condition Details found for this member.
                </td>
            </tr>
    )
}


export default ConditionDetail